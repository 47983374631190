<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลสถานศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="assistantteachs"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="assistantteachEdit(item.assistantID)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model editassistantteachdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editassistantteachdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editassistantteachform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-autocomplete
                      :items="colleges"
                      item-text="college_name"
                      item-value="college_code"
                      outlined
                        label="ชื่อสถานศึกษา"
                        v-model="editassistantteach.assistantCollege"
                        required                       
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                      outlined
                        label="ชื่อ-นามสกุล"
                        v-model="editassistantteach.NameFL"
                        required                        
                      ></v-text-field>
                    </v-flex>
                     <v-flex md6>
                      <v-text-field
                      outlined
                        label="รหัสผ่าน"
                        v-model="editassistantteach.p_word"
                        required
                        type="password"                       
                      ></v-text-field>   
                    </v-flex>
                      <v-flex md6>
                      <v-text-field
                        outlined
                        label="ยืนยันรหัสผ่าน"
                        v-model="editassistantteach.user_confirmpassword"
                        type="password"                     
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editassistantteachdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editassistantteachSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addassistantteachdialog: false,
      editassistantteachdialog: false,
      deleteassistantteachdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      assistantteachs: [],
      addassistantteach: {},
      editassistantteach: {},
      assistantteachs: [],
      userstatus: [],
      search: "",
      pagination: {},
      headers: [
        { text: "idCard", align: "left", value: "idCard" },
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "NameFL" },
        { text: "สาขา", align: "left", value: "branchs" },
        { text: "โทร", align: "left", value: "tels" },
        { text: "การศึกษา", align: "left", value: "educations" },
        { text: "หลักสูตร", align: "left", value: "couses" },
        { text: "สาขาวิชา", align: "left", value: "mainBranch" },
        { text: "ประเภท", align: "left", value: "educations" },
        { text: "วันเดือนปีเกิด", align: "left", value: "birthdays" },
        { text: "เพศ", align: "left", value: "sexs" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
        /*   { text: "ลบ", align: "center", value: "action_s" , icon: "mdi-delete-forever" }, */
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      assistantteach: {},
      provinces: [],
      prefectures: [],
      assistantteachtypes: [],
      collgegs: [],
      assistantteachstatus: [],
      regions: [],
      region_ena: true,
      user_update: {},
      assistantteachinfo_update: {},
      collegetypes:[],
      colleges:[],
      provinces:[],
      regions:[],
    };
  },
  async mounted() {
    let result;
    result = await this.$http.post("collegetype.php", {
      ApiKey: this.ApiKey
    });
    this.collegetypes = result.data;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;
    result = await this.$http.post("province.php", {
      ApiKey: this.ApiKey
    });
    this.provinces = result.data;

    result = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result.data;


    await this.assistantteachQueryAll();
  },

  methods: {
    async assistantteachQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("assistantteach.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.assistantteachs = result.data;
      
    },

    async addassistantteachSubmit() {
      if (this.$refs.addassistantteachform.validate()) {
        this.addassistantteach.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "assistantteach.insert.php",
          this.addassistantteach
        );
        let result_info = await this.$http.post(
          "assistantteachinfo.insert.php",
          this.addassistantteach
        );
        if (result.data.status == true && result_info.data.status == true) {
          this.assistantteachs = result.data;
           Swal.fire({
            icon: 'success',
            title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.assistantteachQueryAll();
        } else {
          Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          this.assistantteachQueryAll();
        }
        this.addassistantteachdialog = false;
      }
    },

    async assistantteachEdit(assistantID) {
      let result = await this.$http.post("assistantteach.php", {
        ApiKey: this.ApiKey,
        assistantID: assistantID
      });
      this.editassistantteach = result.data;
         this.editassistantteach.p_word = "";
      this.editassistantteachdialog = true;
      
    },
    async editassistantteachSubmit() {
      if (this.$refs.editassistantteachform.validate()) {
        this.editassistantteach.ApiKey = this.ApiKey;
        if (this.editassistantteach.p_word == "")
          delete this.editassistantteach.p_word;
        let result = await this.$http.post(
          "assistantteach.update.php",
          this.editassistantteach
        );       
        if (result.data.status == true) {
          this.assistantteach = result.data;
          Swal.fire({
            icon: 'success',
            title: 'ดำเนินการแก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.assistantteachQueryAll();
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.editassistantteachdialog = false;
      }
    },
    async assistantteachDelete(assistantID) {
      let result = await this.$http.post("assistantteach.php", {
        ApiKey: this.ApiKey,
        assistantID: assistantID
      });
      this.editassistantteach = result.data;
      this.deleteassistantteachdialog = true;
    },
    async deleteassistantteachSubmit() {
      if (this.$refs.deleteassistantteachform.validate()) {
        this.editassistantteach.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "assistantteach.delete.php",
          this.editassistantteach
        );
        if (result.data.status == true) {
          this.assistantteach = result.data;
          Swal.fire({
            icon: 'success',
            title: 'ดำเนินการลบข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.assistantteachQueryAll();
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการลบผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.deleteassistantteachdialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
